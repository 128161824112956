/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import Modal from '../../../../ui/modal';
import Cross from '../../../../assets/icons/icon_cross.svg'
import useStyles from './payment-qr-code-modal-style'

export default function PaymentQrCodeModalView({
  isOpen,
  paymentType = 'wechat',
  qrCode,
  onRequestClose,
}) {
  const { t } = useTranslation()
  const {
    closeButton,
    content,
    messageContainer,
    messageText,
    qrcodeContainer,
    qrcodeImage,
    qrCodeModal,
    title,
  } = useStyles()

  const qrcodeProps = {
    bgColor: '#ffffff',
    className: 'qrcode',
    fgColor: '#000000',
    level: 'M',
    size: 200,
    value: qrCode,
  }

  const qrCodeIsImg = qrCode && _.startsWith(qrCode, 'data:image')

  return (
    <Modal
      className={qrCodeModal}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div
        className={closeButton}
        onClick={onRequestClose}
      >
        <img src={Cross} alt="" />
      </div>
      <div className={content}>
        <h2 className={title}>{t('screens.checkout.checkout.paymentQrCodeModal.title')}</h2>
        <div className={qrcodeContainer}>
          {
            qrCode && qrCodeIsImg && (
              <img className={qrcodeImage} src={qrCode} alt={`${paymentType}_qr_code`} />
            )
          }
          {
            qrCode && !qrCodeIsImg && (
              <QRCode {...qrcodeProps} />
            )
          }
        </div>
        <div className={messageContainer}>
          <div className={messageText}>
            {t(`screens.checkout.checkout.paymentQrCodeModal.message.${paymentType}`)}
          </div>
        </div>
      </div>
    </Modal>
  )
}
