import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    paddingBottom: '3rem',
  },

  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    container: {
      paddingBottom: '6rem',
    },

  },
}))

export default useStyles
