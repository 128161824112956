/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  // useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import URI from 'urijs'
import store from 'store'
import {
  cancelRequest,
  useAuth,
  useCart,
  useDeliveryAddresses,
  useOrderMethod,
  useOrders,
  useSystemSettings,
} from 'react-omnitech-api'
import { isBrowser } from '../../helpers'
import {
  useAlert,
  useAnalytics,
  useLink,
  usePriceTemplate,
} from '../../hook'
import CheckoutView from './checkout-view'

const CART_INCLUDES = [
  'await_confirm_order',
  'billing_address',
  'cart_coupon_tokens.coupon_token',
  'cart_coupon_tokens',
  'cart_line_properties.cart_shipment_id',
  'cart_line_properties.color_option',
  'cart_line_properties.price_details',
  'cart_line_properties.sku',
  'cart_line_properties',
  'cart_shipments.courier_service',
  'cart_shipments.courier_service_id',
  'cart_shipments.delivery_address',
  'cart_shipments.delivery_address_id',
  'cart_shipments.delivery_time_slot',
  'cart_shipments.delivery_time_slot_id',
  'cart_shipments.delivery_type',
  'cart_shipments.inventory_store',
  'cart_shipments.pickup_store',
  'cart_shipments.price_details',
  'cart_shipments',
  'categories.department',
  'categories.parent',
  'coupon_tokens.coupon',
  'meta',
  'orders.payment_requests',
  'payment_information',
  'price_details',
  'products.brands',
  'products.categories',
  'products.color_option_variant_type',
  'products.size_option_variant_type',
  'skus.active_custom_labels',
  'skus.color_option',
  'skus.product',
  'skus.size_option',
  'skus.stock_level',
  'stores.address',
  'stores.today_open_time_slots',
].join(',')

function CheckoutController({ location }) {
  // prepare hooks
  const alert = useAlert()
  const { t } = useTranslation()
  const { auth } = useAuth()
  const {
    // cart,
    checkout,
    createCart,
    fetchIsolateCartById,
    // isolateCart,
    updateIsolateCart,
    resetCart,
  } = useCart()
  const {
    deleteDeliveryAddresses,
    updateDeliveryAddresses,
    fetchDeliveryAddresses,
  } = useDeliveryAddresses()
  const { getSystemSetting } = useSystemSettings()
  const { updateOrderToDecline } = useOrders()
  const { navigate } = useLink()
  const { code: CART_PRICE_TEMPLATE_KEY } = usePriceTemplate()
  const { trackEvent } = useAnalytics()
  const {
    store: priceStore,
  } = useOrderMethod()

  // Refs
  const metaTaiwanInvoiceRef = useRef(null)

  // internal states
  const [deliveryAddresses, setDeliveryAddresses] = useState([])
  const [isolateCart, setIsolateCart] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(true)
  const [pageReady, setPageReady] = useState(false)
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState({})
  const [order, setOrder] = useState({})
  const [progress, setProgress] = useState([])

  // local variable
  const deliveryType = _.get(isolateCart, 'cartShipments[0].deliveryType')
  const seoTitle = t('screens.checkout.seo.title')
  const taiwanInvoiceEnabled = getSystemSetting('taiwan_invoice.enable')

  // useMemo
  const { cartQuantityCount, cartSkuCount } = useMemo(() => {
    if (_.isEmpty(isolateCart)) return {}

    const { cartLines } = isolateCart
    return {
      cartQuantityCount: _.reduce(cartLines, (result = 0, current) => (
        result + current.quantity
      ), 0),
      cartSkuCount: _.size(cartLines),
    }
  }, [isolateCart])

  // get parameters from url
  const {
    id: isolateCartId,
    openid,
    originalId,
    errorMessage,
  } = useMemo(() => {
    let search = {}
    if (isBrowser()) {
      const url = new URI(location.href)
      search = url.search(true)
    }
    return search
  }, [location])

  // flag of disable checkout button
  const checkoutDisabled = useMemo(() => {
    if (taiwanInvoiceEnabled) {
      const isValidMetaInvoiceTaiwan = !_.isNull(metaTaiwanInvoiceRef.current)
        && metaTaiwanInvoiceRef.current.validate()
      return _.isNull(isolateCart.checksum)
        || (_.isEmpty(selectedPaymentProvider) || !isValidMetaInvoiceTaiwan)
    }
    return _.isNull(isolateCart.checksum) || _.isEmpty(selectedPaymentProvider)
  }, [isolateCart, selectedPaymentProvider])

  async function apiFetchDeliveryAddresses() {
    const options = {
      params: {
        includes: [
          'user_address_info',
        ].join(','),
        // page,
      },
    }
    const { deliveryAddresses: data } = await fetchDeliveryAddresses(options)
    return data
  }

  async function apiUpdateDeliveryAddress(address) {
    return updateDeliveryAddresses({ address })
  }

  /**
   * apiFetchIsolateCartById
   * call api to fetch isolate cart by isolate cart id
   * @param {*} id
   */
  async function apiFetchIsolateCartById(id) {
    const options = {
      id,
      params: {
        includes: CART_INCLUDES,
        priceTemplate: CART_PRICE_TEMPLATE_KEY,
        priceStoreCode: _.get(priceStore, 'code', null),
        refreshCart: true,
      },
    }
    const data = await fetchIsolateCartById(options)
    return data
  }

  /**
   * apiUpdateIsolateCart
   * call api to update isolate cart
   * @param {*} actions
   */
  async function apiUpdateIsolateCart(actions) {
    const options = {
      payload: {
        data: {
          actions,
        },
      },
      params: {
        includes: CART_INCLUDES,
        priceTemplate: CART_PRICE_TEMPLATE_KEY,
        priceStoreCode: _.get(priceStore, 'code', null),
        refreshCart: true,
      },
    }
    const data = await updateIsolateCart(options)
    return data
  }

  /**
   * handleDeclineOrder
   */
  async function handleDeclineOrder(orderUuid) {
    try {
      await updateOrderToDecline({
        byType: 'by_uuid',
        uuid: orderUuid,
        action: 'decline',
      })
    } catch (error) {
      // suppress errors
    }
  }

  async function handleFetchDeliveryAddresses() {
    try {
      handleSetLoading(true)
      // TODO: set shipment delivery address id
      // when success, show info
      const data = await apiFetchDeliveryAddresses()
      setDeliveryAddresses(data)
      return data
    } catch (error) {
      handleError(error)
    } finally {
      handleSetLoading(false)
    }
  }

  async function handleUpdateDeliveryAddress(address) {
    try {
      handleSetLoading(true)
      // when success, show info
      await apiUpdateDeliveryAddress(address)
      const data = await apiFetchDeliveryAddresses()
      setDeliveryAddresses(data)
    } catch (error) {
      handleError(error)
    } finally {
      handleSetLoading(false)
    }
  }

  /**
   * handleDeleteAddress
   * call api to delete a address
   * @param {*} address
   */
  async function handleDeleteAddress(address) {
    try {
      handleSetLoading(true)
      await deleteDeliveryAddresses({
        addressId: _.get(address, 'id'),
      })
    } catch (error) {
      handleError(error)
    } finally {
      handleSetLoading(false)
    }
  }

  async function handleCheckoutCompleted(order) {
    try {
      await resetCart()
      await createCart({
        params: {
          includes: [],
        },
      })
    } catch (error) {
      console.warn('handleCheckoutCompleted error: ', error)
    } finally {
      handleCheckoutCompleted()
      navigate(`/checkout/completed/?uuid=${order.uuid}&referenceNumber=${order.referenceNumber}`)
    }
  }

  /**
   * handleConfirmCollection
   */
  async function handleConfirmCollection(collectionTime) {
    if (!_.isEmpty(collectionTime)) {
      const actions = [
        {
          actionType: 'update_cart',
          mergeMeta: {
            takeAwayTime: collectionTime,
          },
        },
      ]
      try {
        await handleUpdateIsolateCart(actions)
      } catch (error) {
        handleError(error)
      }
    }
    const shipmentDeliveryType = _.get(isolateCart, 'cartShipments[0].deliveryType.code')
    updateProgress(shipmentDeliveryType)
    trackCheckoutDeliveryAddress(shipmentDeliveryType)
  }

  /**
   * handleConfirmDelivery
   */
  function handleConfirmDelivery() {
    const shipmentDeliveryType = _.get(isolateCart, 'cartShipments[0].deliveryType.code')
    updateProgress(shipmentDeliveryType)
    trackCheckoutDeliveryAddress(shipmentDeliveryType)
  }

  /**
   * handleConfirmPlaceOrder
   */
  async function handleConfirmPlaceOrder() {
    try {
      if (!validateTaiwanInvoice()) {
        throw new Error(t('screens.checkout.metaInvoice.invoiceOptionInvalid'))
      }

      const includes = [
        'price_details',
        'payment_requests',
      ].join(',')
      const option = {
        cartId: isolateCart.id,
        checksum: isolateCart.checksum,
        params: {
          includes,
          priceTemplate: CART_PRICE_TEMPLATE_KEY,
          priceStoreCode: _.get(priceStore, 'code', null),
        },
        payload: {
          schemaVersion: '2019-07-20',
        },
      }
      const { order: data } = await checkout(option)
      trackCheckoutConfirm()
      setOrder(data)
      return data
    } catch (error) {
      handleError(error)
      throw error
    }
  }
  /**
   * refresh cart
   * */
  async function handleRefreshIsolateCart() {
    try {
      const { cart: data } = await apiFetchIsolateCartById(isolateCartId)
      setIsolateCart(data)
      return data
    } catch (error) {
      // console.error('[Project] handleFetchIsolateCart error: ', error)
      handleError(error)
      // go back to 'cart page' if there is error while loading isolate cart
      navigate('/cart/')
    }
  }

  /**
   * handleFetchIsolateCart
   */
  async function handleFetchIsolateCart(id) {
    try {
      const { cart: data } = await apiFetchIsolateCartById(id)
      setIsolateCart(data)
      return data
    } catch (error) {
      // console.error('[Project] handleFetchIsolateCart error: ', error)
      handleError(error)
      // go back to 'cart page' if there is error while loading isolate cart
      navigate('/cart/')
    }
  }

  /**
   * handleUpdateIsolateCart
   */
  async function handleUpdateIsolateCart(actions) {
    try {
      handleSetLoading(true)
      const { cart: data } = await apiUpdateIsolateCart(actions)
      setIsolateCart(data)
      return data
    } catch (error) {
      // console.error('[Project] handleUpdateIsolateCart error: ', error)
      handleError(error)
    } finally {
      handleSetLoading(false)
    }
  }

  /**
   * handleError
   * show error message via alert
   */
  function handleError(error) {
    if (_.get(error, 'isCancel', false)) return
    const message = _.get(error, 'generalError.message', error.toString())
    alert.show(message)
  }

  function resetProgress() {
    setProgress([])
  }

  function updateProgress(currentStep) {
    const shipmentDeliveryType = _.get(isolateCart, 'cartShipments[0].deliveryType.code')
    let newProgress = [...progress]

    switch (currentStep) {
      case shipmentDeliveryType:
        newProgress = newProgress.concat(['payment_method', 'coupon', 'confirmation'])
        break
      // first step of checkout which base on delivery type
      default:
        newProgress = newProgress.concat([shipmentDeliveryType])
        break
    }
    setProgress(newProgress)
  }

  /**
   * prepareCheckout
   * - preload data required for checkout
   * - defined progress, required step
   * - set pageReady
   */
  async function prepareCheckout() {
    const previousOrderUuid = store.get('pr3v0u5O4de4')

    // decline previous order if it is exist
    if (!_.isEmpty(previousOrderUuid)) {
      await handleDeclineOrder(previousOrderUuid)
    }
    store.remove('pr3v0u5O4de4')
    store.remove('openid')

    await handleFetchIsolateCart(isolateCartId)
    await handleFetchDeliveryAddresses()
    resetProgress()
    setPageReady(true)
  }

  function handleSetLoading(value) {
    setIsLoading(value)
  }

  async function handleAddCartMeta({ newMeta, removeKeys = [] } = {}) {
    try {
      handleSetLoading(true)
      const actions = [
        {
          actionType: 'update_cart',
          mergeMeta: newMeta,
          removeMeta: removeKeys || undefined,
        },
      ]
      const { cart: data } = await apiUpdateIsolateCart(actions)
      setIsolateCart(data)
    } catch (error) {
      handleError(error)
      throw error
    } finally {
      handleSetLoading(false)
    }
  }

  async function handleAddCoupon(couponTokenToken) {
    try {
      handleSetLoading(true)
      const actions = [{
        actionType: 'apply_coupon_token',
        couponTokenToken,
      }]
      const { cart: data } = await apiUpdateIsolateCart(actions)
      setIsolateCart(data)
      return data
    // eslint-disable-next-line no-useless-catch
    } catch (error) {
      // console.error('[Project] handleAddCoupon error: ', error)
      // handleError(error)
      throw error
    } finally {
      handleSetLoading(false)
    }
  }

  async function handleApplyCouponWithCouponMarketplace(couponTokenToken) {
    const actions = [{
      actionType: 'apply_coupon_token',
      couponTokenToken,
    }]
    const { cart: data } = await apiUpdateIsolateCart(actions)
    setIsolateCart(data)
  }

  async function handleRemoveCoupon(couponTokenToken) {
    try {
      handleSetLoading(true)
      const actions = [{
        actionType: 'remove_coupon_token',
        couponTokenToken,
      }]
      const { cart: data } = await apiUpdateIsolateCart(actions)
      setIsolateCart(data)
      return data
    // eslint-disable-next-line no-useless-catch
    } catch (error) {
      // console.error('[Project] handleRemoveCoupon error: ', error)
      // handleError(error)
      throw error
    } finally {
      handleSetLoading(false)
    }
  }

  async function handleSelectBillingAddress(billingAddressId) {
    try {
      handleSetLoading(true)
      const actions = [{
        actionType: 'update_cart',
        billingAddressId,
      }]
      const { cart: data } = await apiUpdateIsolateCart(actions)
      setIsolateCart(data)
      return data
    } catch (error) {
      // console.error('[Project] handleSelectBillingAddress error: ', error)
      handleError(error)
    } finally {
      handleSetLoading(false)
    }
  }

  async function handleSelectDeliveryAddress(address) {
    try {
      // TODO: loading
      const shipmentId = _.get(isolateCart, 'cartShipments[0].id')
      const deliveryType = _.get(isolateCart, 'cartShipments[0].deliveryType.code')
      const actions = [
        {
          actionType: 'update_cart_shipment',
          id: shipmentId,
          deliveryType,
          deliveryAddressId: address.id,
          courierServiceId: null,
          deliveryDate: null,
          deliveryTimeSlotId: null,
        },
      ]
      await handleUpdateIsolateCart(actions)
    } catch (error) {
      handleError(error)
    }
  }
  async function handleSelectCourierService(courierService) {
    try {
      // TODO: loading
      const shipmentId = _.get(isolateCart, 'cartShipments[0].id')
      const actions = [
        {
          actionType: 'update_cart_shipment',
          id: shipmentId,
          courierServiceId: courierService.id,
          deliveryDate: null,
          deliveryTimeSlotId: null,
        },
      ]
      await handleUpdateIsolateCart(actions)
    } catch (error) {
      handleError(error)
    }
  }
  async function handleSelectDeliveryDate(date) {
    if (_.isEmpty(date)) {
      return
    }

    const shipmentId = _.get(isolateCart, 'cartShipments[0].id')
    const actions = [
      {
        action_type: 'update_cart_shipment',
        id: shipmentId,
        deliveryDate: date,
        deliveryTimeSlotId: null,
      },
    ]
    try {
      await handleUpdateIsolateCart(actions)
    } catch (error) {
      handleError(error)
    }
  }
  async function handleSelectDeliveryTimeSlot(timeSlotId) {
    const shipmentId = _.get(isolateCart, 'cartShipments[0].id')
    const actions = [
      {
        action_type: 'update_cart_shipment',
        id: shipmentId,
        deliveryTimeSlotId: timeSlotId,
      },
    ]
    try {
      await handleUpdateIsolateCart(actions)
    } catch (error) {
      handleError(error)
    }
  }
  async function handleSelectPaymentMethod(paymentProvider) {
    setSelectedPaymentProvider(paymentProvider)
  }

  function handleUpdateBillingSameAsShipping() {
    setBillingSameAsShipping(!billingSameAsShipping)
  }

  function trackCheckoutDeliveryAddress(deliveryType) {
    const { cartLineProperties } = isolateCart
    trackEvent('customerCheckoutDeliveryAddress',
      { deliveryType },
      { cartLineProperties })
  }

  function trackCheckoutConfirm() {
    const { cartLineProperties } = isolateCart
    trackEvent('customerCheckoutConfirm', {}, { cartLineProperties, title: seoTitle })
  }

  function validateTaiwanInvoice() {
    if (!taiwanInvoiceEnabled) {
      return true
    }
    if (_.isNull(metaTaiwanInvoiceRef.current)) {
      return false
    }
    return metaTaiwanInvoiceRef.current.validate()
  }

  /**
   * when checkout page is load, start prepare checkout
   */
  useEffect(() => {
    // without user logged in or cart id, redirect to cart
    if (!auth.userId) {
      navigate(
        '/login/',
        {
          state: {
            redirectUrl: `/cart/?id=${originalId}`,
          },
          replace: true,
        },
      )
      return
    }

    prepareCheckout()

    return function cleanUp() {
      cancelRequest.cancelAll([
        'fetchIsolateCartById',
        'deleteDeliveryAddresses',
        'updateDeliveryAddresses',
        'fetchDeliveryAddresses',
      ])
    }
  }, [])

  /**
   * when isolate cart is ready and progress is not start yet,
   * start first step base on delivery type
   */
  useEffect(() => {
    if (_.isEmpty(isolateCart) || !_.isEmpty(progress)) return

    updateProgress()
  }, [isolateCart, progress])

  /**
   * when page is ready, display payment error message from payment processing checking
   */
  useEffect(() => {
    if (_.isEmpty(errorMessage) || !pageReady) return

    alert.show(errorMessage)
  }, [errorMessage, pageReady])

  const viewProps = {
    billingSameAsShipping,
    cartQuantityCount,
    cartSkuCount,
    checkoutDisabled,
    deliveryAddresses,
    deliveryType,
    isolateCart,
    isLoading,
    pageReady,
    openid,
    order,
    progress,
    ref: {
      metaTaiwanInvoiceRef,
    },
    selectedPaymentProvider,
    seoTitle,
    taiwanInvoiceEnabled,
    onAddCartMeta: handleAddCartMeta,
    onAddCoupon: handleAddCoupon,
    onApplyCouponWithCouponMarketplace: handleApplyCouponWithCouponMarketplace,
    onCheckoutCompleted: handleCheckoutCompleted,
    onConfirmCollection: handleConfirmCollection,
    onConfirmDelivery: handleConfirmDelivery,
    onConfirmPlaceOrder: handleConfirmPlaceOrder,
    onDeclineOrder: handleDeclineOrder,
    onError: handleError,
    onFetchDeliveryAddresses: handleFetchDeliveryAddresses,
    onDeleteAddress: handleDeleteAddress,
    onRemoveCoupon: handleRemoveCoupon,
    onSelectBillingAddress: handleSelectBillingAddress,
    // onSelectCollectionDate: handleSelectCollectionDate,
    // onSelectCollectionTimeSlot: handleSelectCollectionTimeSlot,
    onSelectCourierService: handleSelectCourierService,
    onSelectDeliveryAddress: handleSelectDeliveryAddress,
    onSelectDeliveryDate: handleSelectDeliveryDate,
    onSelectDeliveryTimeSlot: handleSelectDeliveryTimeSlot,
    onSelectPaymentMethod: handleSelectPaymentMethod,
    onSetLoading: handleSetLoading,
    onUpdateBillingSameAsShipping: handleUpdateBillingSameAsShipping,
    onUpdateDeliveryAddress: handleUpdateDeliveryAddress,
    onUpdateIsolateCart: handleUpdateIsolateCart,
    onApiRefreshIsolateCart: handleRefreshIsolateCart,
    resetProgress,
  }

  return (
    <CheckoutView {...viewProps} />
  )
}

export default CheckoutController
