/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import useStyles from './delivery-style'
import Title from '../title/title-view'
import {
  Shipping,
} from './components'
import Info from '../info/info-view'
import { AddressManager } from '../../../../ui'
import getStoreInfoFromDescription from '../../../../helpers/get-store-info-from-description'

const DeliveryView = ({
  isolateCart,
  availableCourierServices,
  availableDeliveryDate,
  availableDeliveryTimeSlots,
  defaultDeliveryAddress,
  deliveryAddresses,
  deliveryDateUsable,
  deliveryTimeSlotUsable,
  isGuest,
  shipment,
  shipmentDeliveryAddress,
  showAddressBook,
  showAddressForm,
  submitDisabled,
  onConfirmDelivery,
  onDeleteAddress,
  onFetchDeliveryAddresses,
  showDeliveryDateTimeOptions,
  onCloseAddressBook,
  onOpenAddressBook,
  onSelectAddress,
  onSelectDeliveryDate,
  onSelectDeliveryTimeSlot,
  onSelectCourierService,
  onUpdateDeliveryAddress,
  onOpenDeliveryDateTimeOptions,
  requireDeliveryAddress,
  requireCourierService,
  requirePickupStore,
}) => {
  // prepare hook
  const { t } = useTranslation()

  // style
  const styles = useStyles()

  const isCatering = !requireDeliveryAddress && requireCourierService && requirePickupStore

  // Handle Address from Description
  // get pickup store info if available
  let infoAddress = null
  const store = _.get(shipment, 'pickupStore', {})
  if (isCatering && store) {
    const { address = {}, description = '' } = store
    const {
      formattedAddressLines = [],
      ...otherAddressProps
    } = address
    const descriptionHtml = (description || '').trim()
    const descriptionAddressLines = _.map(getStoreInfoFromDescription('address', descriptionHtml), 'content')
    const addressLines = _.isEmpty(descriptionAddressLines)
      ? formattedAddressLines
      : descriptionAddressLines
    infoAddress = {
      formattedAddressLines: addressLines,
      ...otherAddressProps,
    }
  }

  return (
    <div className={styles.container}>
      <Title
        isActive
        isEditable={!showDeliveryDateTimeOptions}
        t={t}
        text={isCatering ? t('screens.checkout.checkout.catering.title') : t('screens.checkout.checkout.delivery.title')}
        buttonText={t('screens.checkout.checkout.buttons.edit')}
        onEdit={onOpenDeliveryDateTimeOptions}
      />
      {
        requireDeliveryAddress && (showAddressBook || showAddressForm)
          ? (
            <AddressManager
              addresses={deliveryAddresses}
              addressSelectable
              addressType="delivery"
              autoSelectPrimaryAddress
              closeAfterSelectAddress
              defaultAddress={defaultDeliveryAddress}
              disableAddressBook={isGuest}
              selectedAddress={shipmentDeliveryAddress}
              onAddress
              onClose={onCloseAddressBook}
              onDeleteAddress={onDeleteAddress}
              onFetchDeliveryAddresses={onFetchDeliveryAddresses}
              onSelectAddress={onSelectAddress}
              onUpdateDeliveryAddress={onUpdateDeliveryAddress}
            />
          )
          : (
            <>
              { isCatering
                ? (
                  <Info
                    t={t}
                    isAddress
                    isHtmlAddress
                    address={infoAddress}
                    title={t('screens.checkout.checkout.collection.subtitle')}
                  />
                ) : (
                  <Info
                    t={t}
                    isAddress
                    address={shipmentDeliveryAddress}
                    title={t('screens.checkout.checkout.delivery.subtitle')}
                    onOpenAddressBook={onOpenAddressBook}
                    canOpenAddressBook={showDeliveryDateTimeOptions}
                  />
                ) }
              {
                showDeliveryDateTimeOptions
                  ? (
                    <Shipping
                      availableCourierServices={availableCourierServices}
                      availableDeliveryDate={availableDeliveryDate}
                      availableDeliveryTimeSlots={availableDeliveryTimeSlots}
                      deliveryDateUsable={deliveryDateUsable}
                      deliveryTimeSlotUsable={deliveryTimeSlotUsable}
                      isolateCart={isolateCart}
                      submitDisabled={submitDisabled}
                      onConfirmDelivery={onConfirmDelivery}
                      onSelectCourierService={onSelectCourierService}
                      onSelectDeliveryDate={onSelectDeliveryDate}
                      onSelectDeliveryTimeSlot={onSelectDeliveryTimeSlot}
                      requireDeliveryAddress={requireDeliveryAddress}
                      requireCourierService={requireCourierService}
                    />
                  )
                  : (
                    <Info
                      t={t}
                      shipment={shipment}
                      address={shipmentDeliveryAddress}
                      title={t('screens.checkout.checkout.shipping.title')}
                    />
                  )
              }
            </>
          )
      }
    </div>
  )
}

export default DeliveryView
