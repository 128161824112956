import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Collection,
  Confirmation,
  Coupons,
  Delivery,
  MetaTwInvoice,
  Payment,
  Summary,
} from './components'
import {
  LayoutCheckout,
  Loading,
  MiniCartCoupon,
  SEO,
} from '../../ui'
import useStyles from './checkout-style'

const CheckoutView = ({
  billingSameAsShipping,
  cartQuantityCount,
  checkoutDisabled,
  deliveryAddresses,
  deliveryType,
  isolateCart,
  isLoading,
  openid,
  pageReady,
  progress,
  selectedPaymentProvider,
  seoTitle,
  taiwanInvoiceEnabled,
  onAddCartMeta,
  onAddCoupon,
  onApplyCouponWithCouponMarketplace,
  onCheckoutCompleted,
  onConfirmCollection,
  onConfirmDelivery,
  onConfirmPlaceOrder,
  onDeclineOrder,
  onDeleteAddress,
  onError,
  onFetchDeliveryAddresses,
  onRemoveCoupon,
  onSelectBillingAddress,
  onSelectCollectionDate,
  onSelectCollectionTimeSlot,
  onSelectCourierService,
  onSelectDeliveryAddress,
  onSelectDeliveryDate,
  onSelectDeliveryTimeSlot,
  onSelectPaymentMethod,
  onSetLoading,
  onUpdateBillingSameAsShipping,
  onUpdateDeliveryAddress,
  resetProgress,
  onApiRefreshIsolateCart,
}, ref) => {
  const { t } = useTranslation()

  const {
    metaTaiwanInvoiceRef,
  } = ref

  const {
    requireCourierService,
    requireDeliveryAddress,
    requirePickupStore,
  } = deliveryType || {}

  // prepare the data
  const { cartCouponTokens, cartLineProperties, priceDetails = {} } = isolateCart
  // summary object for summary component
  const summary = {
    quantityCount: cartQuantityCount,
    deliveryFee: priceDetails.deliveryFee || priceDetails.cartDeliveryFee,
    discount: priceDetails.discount || priceDetails.totalDiscount || priceDetails.cartDiscount,
    discountGroup: priceDetails.discountGroup || priceDetails.cartDiscountGroup,
    subtotalPrice: priceDetails.subtotalPrice || priceDetails.cartSubtotalPrice,
    totalPrice: priceDetails.totalPrice || priceDetails.cartTotalPrice,
  }
  const {
    containerFluid,
    container,
    row,
    stepsStyles,
    summaryBoxStyles,
  } = useStyles()
  return (
    <LayoutCheckout>
      {/* passing page specific data for SEO */}
      <SEO title={seoTitle} />
      {pageReady && (
        <div className={containerFluid}>
          <div className={container}>
            <h2>{t('screens.checkout.checkout.title')}</h2>
            <div className={row}>
              <div className={stepsStyles}>
                {
                  (requireDeliveryAddress || requireCourierService) && (
                    <Delivery
                      deliveryAddresses={deliveryAddresses}
                      isolateCart={isolateCart}
                      progress={progress}
                      onConfirmDelivery={onConfirmDelivery}
                      onError={onError}
                      onSelectCourierService={onSelectCourierService}
                      onSelectDeliveryAddress={onSelectDeliveryAddress}
                      onSelectDeliveryDate={onSelectDeliveryDate}
                      onSelectDeliveryTimeSlot={onSelectDeliveryTimeSlot}
                      onFetchDeliveryAddresses={onFetchDeliveryAddresses}
                      onDeleteAddress={onDeleteAddress}
                      onUpdateDeliveryAddress={onUpdateDeliveryAddress}
                      onSetLoading={onSetLoading}
                      resetProgress={resetProgress}
                      onApiRefreshIsolateCart={onApiRefreshIsolateCart}
                      requireDeliveryAddress={requireDeliveryAddress}
                      requireCourierService={requireCourierService}
                    />
                  )
                }
                {
                  requirePickupStore && (
                    <Collection
                      isolateCart={isolateCart}
                      progress={progress}
                      onConfirmCollection={onConfirmCollection}
                      onSelectCollectionDate={onSelectCollectionDate}
                      onSelectCollectionTimeSlot={onSelectCollectionTimeSlot}
                    />
                  )
                }
                <Payment
                  billingSameAsShipping={billingSameAsShipping}
                  deliveryAddresses={deliveryAddresses}
                  deliveryType={deliveryType}
                  isolateCart={isolateCart}
                  progress={progress}
                  selectedPaymentProvider={selectedPaymentProvider}
                  onError={onError}
                  onFetchDeliveryAddresses={onFetchDeliveryAddresses}
                  onDeleteAddress={onDeleteAddress}
                  onSelectBillingAddress={onSelectBillingAddress}
                  onSelectPaymentMethod={onSelectPaymentMethod}
                  onSetLoading={onSetLoading}
                  onUpdateBillingSameAsShipping={onUpdateBillingSameAsShipping}
                  onUpdateDeliveryAddress={onUpdateDeliveryAddress}
                />
                <Coupons
                  isolateCart={isolateCart}
                  progress={progress}
                  onAddCoupon={onAddCoupon}
                  onRemoveCoupon={onRemoveCoupon}
                />
                {
                  taiwanInvoiceEnabled
                    ? (
                      <MetaTwInvoice
                        deliveryAddresses={deliveryAddresses}
                        isolateCart={isolateCart}
                        progress={progress}
                        ref={metaTaiwanInvoiceRef}
                        onAddCartMeta={onAddCartMeta}
                        onDeleteAddress={onDeleteAddress}
                        onFetchDeliveryAddresses={onFetchDeliveryAddresses}
                        onUpdateDeliveryAddress={onUpdateDeliveryAddress}
                      />
                    )
                    : null
                }
                <Confirmation
                  checkoutDisabled={checkoutDisabled}
                  isolateCart={isolateCart}
                  openid={openid}
                  selectedPaymentProvider={selectedPaymentProvider}
                  progress={progress}
                  onCheckoutCompleted={onCheckoutCompleted}
                  onConfirmPlaceOrder={onConfirmPlaceOrder}
                  onDeclineOrder={onDeclineOrder}
                  onError={onError}
                  onSetLoading={onSetLoading}
                />
              </div>
              <div className={summaryBoxStyles}>
                <Summary
                  cartLines={cartLineProperties}
                  summary={summary}
                />
              </div>
            </div>

          </div>
        </div>
      )}
      {
        (!pageReady || isLoading) && (
          <Loading />
        )
        }
      <MiniCartCoupon
        cartCouponTokens={cartCouponTokens}
        onApplyCouponWithCouponMarketplace={onApplyCouponWithCouponMarketplace}
      />
    </LayoutCheckout>
  )
}

export default forwardRef(CheckoutView)
