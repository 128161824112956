import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  promoCodeStyle: {
    textAlign: 'left',
    '& h4': {
      margin: '4.8rem 0 2rem',
      fontSize: '1.4rem',
      textTransform: 'uppercase',
    },
  },
  boxCoupon: {
    display: 'flex',
    marginBottom: '2rem',
    marginTop: '2.4rem',
    '& div[class*="input"]': {
      marginTop: 0,
      marginRight: '2rem',
      '& input': {
        minHeight: '4rem',

      },
    },
    '& button': {
      padding: '0.7rem',
      width: '16rem',
      margin: '0',
    },
  },
  applyButton: {
    padding: '0.7rem',
    width: '16rem',
    margin: '0',
    minHeight: 0,
  },
  redeemButtonStyle: {
    marginTop: '2rem',
    padding: '1.2rem',
    border: `1px solid ${theme.colors.text}`,
    fontWeight: 600,
    marginBottom: '4.3rem',
    color: theme.colors.text,
  },
  errorMessage: {
    fontSize: '1.2rem',
    color: theme.colors.alert,
    lineHeight: 1,
    marginBottom: 0,
  },
  isClose: {
    marginBottom: '4rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    isClose: {
      padding: '0 0 4rem',
    },
  },
}))

export default useStyles
