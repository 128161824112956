/* eslint-disable react/jsx-no-bind */
import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import useStyles from './shipping-style'
import { InputRadio, CurrencyFormat, Button } from '../../../../../../ui';
import SelectTimeDelivery from '../select-time-delivery/select-time-delivery-view';

const ShippingView = ({
  availableCourierServices,
  availableDeliveryDate,
  availableDeliveryTimeSlots,
  deliveryDateUsable,
  deliveryTimeSlotUsable,
  isolateCart,
  submitDisabled,
  onConfirmDelivery,
  onSelectCourierService,
  onSelectDeliveryDate,
  onSelectDeliveryTimeSlot,
  requireDeliveryAddress,
  requireCourierService,
}) => {
  // prepare hook
  const { t } = useTranslation()

  // local variables
  const shipment = _.get(isolateCart, 'cartShipments[0]')
  const shippingFee = _.get(isolateCart, 'priceDetails.cartDeliveryFee')
  const shipmentCourierServiceId = _.get(isolateCart, 'cartShipments[0].courierService.id')

  // styles
  const style = useStyles()

  return (
    <div className={style.shipping}>
      <h4>{t('screens.checkout.checkout.shipping.title')}</h4>
      <div>
        <form>
          {
            availableCourierServices.map((courierService) => {
              const isSelected = shipmentCourierServiceId === courierService.id
              return (
                <React.Fragment key={`courier-service-${courierService.id}`}>
                  { availableCourierServices.length > 1
                    && (
                    <label htmlFor={courierService.id} className={style.optionsStyle}>
                      <div className={style.boxInput}>
                        <InputRadio
                          id={courierService.id}
                          checked={isSelected}
                          onChange={onSelectCourierService.bind(this, courierService)}
                        />
                      </div>
                      <div className={style.contentOptions}>
                        <h6>{courierService.name}</h6>
                        { courierService.summary && <p>{courierService.summary}</p> }
                        {
                          shippingFee && isSelected && (
                            <h6><CurrencyFormat value={shippingFee} /></h6>
                          )
                        }
                      </div>
                    </label>
                    ) }
                  {
                    isSelected && (
                      <SelectTimeDelivery
                        shipment={shipment}
                        t={t}
                        availableDeliveryDate={availableDeliveryDate}
                        availableDeliveryTimeSlots={availableDeliveryTimeSlots}
                        deliveryDateUsable={deliveryDateUsable}
                        deliveryTimeSlotUsable={deliveryTimeSlotUsable}
                        onSelectDeliveryDate={onSelectDeliveryDate}
                        onSelectDeliveryTimeSlot={onSelectDeliveryTimeSlot}
                        requireDeliveryAddress={requireDeliveryAddress}
                        requireCourierService={requireCourierService}
                      />
                    )
                  }
                </React.Fragment>
              )
            })
          }
          <Button
            dark
            disabled={submitDisabled}
            className={style.button}
            text={t('screens.checkout.checkout.buttons.continue')}
            onClick={onConfirmDelivery}
          />
        </form>
      </div>
    </div>
  )
}

export default ShippingView
