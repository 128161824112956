import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[15, 20, 0]],
  },
  container: {
    textAlign: 'center',
    '& h2': {
      lineHeight: 1.17,
      fontSize: '2.4rem',
      marginTop: '4.5rem',
      marginBottom: '7.2rem',
      textTransform: 'uppercase',
      color: theme.colors.text,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  stepsStyles: {

  },
  summaryBoxStyles: {

  },

  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    containerFluid: {
      padding: 0,
      width: '100%',
      textAlign: 'center',
    },
    container: {
      width: '100%',
      margin: 0,
      padding: '0 20px',
      display: 'flex',
      flexDirection: 'column',
      '& h2': {
        lineHeight: 1.17,
        fontSize: '2.4rem',
        marginTop: '6rem',
        marginBottom: '7.5rem',
      },
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    stepsStyles: {
      width: '65rem',
    },
    summaryBoxStyles: {
      width: '33rem',
      marginLeft: '2rem',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    container: {
      width: 1170,
      margin: [[0, 'auto', 152]],
    },
    stepsStyles: {
      width: '67rem',
    },
    summaryBoxStyles: {
      width: '37rem',
    },
  },
}))

export default useStyles
