/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import useStyles from './title-style'
import { Button } from '../../../../ui'

const TitleView = ({
  text,
  isActive,
  buttonText,
  isEditable,
  onEdit,
}) => {
  const [actived, setActived] = React.useState(false)

  useEffect(() => {
    setActived(isActive)
  }, [isActive])
  const { titleStyle } = useStyles({ actived })

  return (
    <div className={titleStyle}>
      <p>{text}</p>
      {
        isEditable && <Button text={buttonText} onClick={onEdit} />
      }
    </div>
  )
}

export default TitleView
