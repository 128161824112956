import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  invoiceRoot: {
    textAlign: 'left',
    '& h4': {
      margin: '4.8rem 0 2rem',
      fontSize: '1.4rem',
      textTransform: 'uppercase',
    },
  },
  isClose: {
    marginBottom: '4rem',
  },
  contentOptions: {
    '& h6': {
      lineHeight: 1.15,
      fontSize: '1.4rem',
      margin: 0,
    },
    '& p': {
      marginBottom: 5,
      color: theme.colors.bgDark,
      lineHeight: 1.43,
    },
  },
  dropdown: {
    paddingTop: '2.5rem',
    width: '100%',
    '& label': {
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontWeight: 600,
      letterSpacing: -0.45,
    },
  },
  error: {
    fontSize: '1.4rem',
    lineHeight: 1.14,
    color: theme.colors.alert,
    padding: '.8rem 0',
    marginBottom: 0,
  },
  options: {
    display: 'flex',
    minHeight: '3rem',
    marginBottom: '2.5rem',
    alignItems: 'center',
  },
  boxInput: {
    display: 'flex',
    minWidth: '3rem',
    padding: '0 2rem 0 0',
    alignItems: 'center',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    isClose: {
      padding: '0 0 4rem',
    },
    dropdown: {
      width: 'calc(60% - 1rem)',
      maxWidth: '37rem',
    },
  },
}))

export default useStyles
