import { createUseStyles } from 'react-jss'
import Color from 'color'

const useStyles = createUseStyles((theme) => ({
  collectionStyle: {
    paddingBottom: '3rem',
    textAlign: 'left',
    '& h4': {
      textTransform: 'uppercase',
      fontSize: '1.6rem',
      fontWeight: 600,
      marginTop: '3rem',
      marginBottom: '2rem',
    },
    '& p': {
      fontSize: '1.4rem',
    },
    '& h5': {
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontWeight: 600,
      marginTop: '3rem',
      marginBottom: '0.5rem',
    },
  },
  btnContinueStyle: {
    padding: '1.25rem',
  },
  selectedDateStyle: {
    fontSize: '1.4rem',
    margin: 0,
    lineHeight: 1.43,
  },
  titleTimeStyle: (props) => ({
    color: Color(theme.colors.primary).alpha(0.2).string(),
    ...(props.confirmed
      ? {
        color: theme.colors.primary,
      }
      : {}),
  }),
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    collectionStyle: {
      paddingBottom: '6rem',
    },

  },
}))

export default useStyles
