import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  titleStyle: (props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.colors.bgDark}`,
    width: '100%',
    textAlign: 'left',
    '& button': {
      textTransform: 'uppercase',
      width: '5rem',
      margin: 0,
      padding: 0,
      marginBottom: '0.8rem',
    },
    '& p': {
      textTransform: 'uppercase',
      fontSize: '1.8rem',
      fontWeight: 600,
      marginBottom: '0.8rem',
      color: theme.colors.bgDark,
      ...(props.actived
        ? {
          color: theme.colors.text,
          ...props.actived,
        }
        : {}),
    },
    ...(props.actived
      ? {
        borderBottom: `2px solid ${theme.colors.text}`,
        ...props.actived,
      }
      : {}),
  }),

  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {

  },
}))

export default useStyles
