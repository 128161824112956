/* eslint-disable no-unused-vars */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useLink } from '../../../../hook'
import { groupCartLineProperties } from '../../../../helpers'
import SummaryView from './summary-view'

const SummaryController = ({
  cartLines,
  summary,
}) => {
  // prepare hook
  const { navigate } = useLink()

  function handleEditCart() {
    navigate('/cart')
  }

  const groupedCartLineProperties = useMemo(() => (groupCartLineProperties(cartLines)), [cartLines])

  const viewProps = {
    cartLines: groupedCartLineProperties,
    summary,
    onEditCart: handleEditCart,
  }

  return (
    <SummaryView {...viewProps} />
  )
}

export default SummaryController
