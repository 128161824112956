/* eslint-disable prefer-destructuring */
/* eslint-disable no-useless-catch */

import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import toNumber from 'lodash/fp/toNumber'
import store from 'store'
import { useTranslation } from 'react-i18next'
import {
  usePayment,
  useSystemSettings,
} from 'react-omnitech-api'
import axios from 'axios'
import { useLink } from '../../../../hook'
import { isBrowser } from '../../../../helpers'
import ConfirmationView from './confirmation-view'

const ConfirmationController = ({
  checkoutDisabled,
  isolateCart,
  openid,
  progress,
  selectedPaymentProvider,
  onCheckoutCompleted,
  onConfirmPlaceOrder,
  onDeclineOrder,
  onError,
  onSetLoading,
}) => {
  const ApplePaySession = isBrowser() ? window.ApplePaySession : {}
  const [isApplePayConfirmationDialogOpen, setIsApplePayConfirmationDialogOpen] = useState(false)
  const applePayPaymentRequestRef = useRef()
  const orderUuidRef = useRef()
  const [paymentQrCodeModalOpen, setPaymentQrCodeModalOpen] = useState(false)
  const [paymentQrCodeModalParams, setPaymentQrCodeModalParams] = useState({})
  // prepare hook
  const { t } = useTranslation()
  const { getPath, navigate } = useLink()
  const {
    createPaymentRequest,
    // paymentRequest,
    createPaymentResponse,
    createApplePayMerchantSession,
    // resetPayment,
    fetchQfpayOverseaOauthUrl,
  } = usePayment()
  const { getSystemSetting } = useSystemSettings()

  const tempCacheKey = 'pr3v0u5O4de4'

  // useMemo
  const active = useMemo(() => (
    progress.indexOf('confirmation') !== -1
  ), [progress])

  // TODO: move to payment services
  // CYBS_VM
  // cybersource mode `sa`
  function submitCybersourceForm({ paymentRequest }) {
    const { paymentData = {} } = paymentRequest
    const { payUrl, payParams } = paymentData
    const paymentForm = document.createElement('form')
    paymentForm.setAttribute('id', `om-payment-form-${paymentRequest.id}`)
    paymentForm.setAttribute('method', 'post')
    paymentForm.setAttribute('action', payUrl)

    _.each(payParams, (value, key) => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', value)
      paymentForm.appendChild(input)
    })

    const docBody = document.querySelector('body')

    docBody.appendChild(paymentForm)

    paymentForm.submit()
  }

  // TODO: move to payment services
  // pay2go_pay2go
  function submitPay2goForm({ paymentRequest }) {
    const { paymentData = {} } = paymentRequest
    const { pay2GoUrl: payUrl, pay2GoParams: payParams } = paymentData
    const paymentForm = document.createElement('form')
    paymentForm.setAttribute('id', `om-payment-form-${paymentRequest.id}`)
    paymentForm.setAttribute('method', 'post')
    paymentForm.setAttribute('action', payUrl)

    _.each(payParams, (value, key) => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', value)
      paymentForm.appendChild(input)
      if (key === 'MerchantID') {
        const secondMerchantIDInput = document.createElement('input')
        secondMerchantIDInput.setAttribute('type', 'hidden')
        secondMerchantIDInput.setAttribute('name', 'merchant_id')
        secondMerchantIDInput.setAttribute('value', value)
        paymentForm.appendChild(secondMerchantIDInput)
      }
    })

    const docBody = document.querySelector('body')

    docBody.appendChild(paymentForm)

    paymentForm.submit()
  }

  function submitNewebpayForm({ paymentRequest }) {
    const { paymentData = {} } = paymentRequest
    const { newebpayUrl: payUrl, newebpayParams: payParams } = paymentData
    const paymentForm = document.createElement('form')
    paymentForm.setAttribute('id', `om-payment-form-${paymentRequest.id}`)
    paymentForm.setAttribute('method', 'post')
    paymentForm.setAttribute('action', payUrl)

    _.each(payParams, (value, key) => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', value)
      paymentForm.appendChild(input)
    })

    const docBody = document.querySelector('body')

    docBody.appendChild(paymentForm)

    paymentForm.submit()
  }

  function applePayBeginPayment({ paymentRequest }) {
    const { paymentData = {}, id: paymentRequestId } = paymentRequest

    const applepayRequest = _.get(paymentData, 'applepayRequest', {})
    const version = 3
    try {
      const session = new ApplePaySession(version, applepayRequest)
      onSetLoading(true)
      session.onvalidatemerchant = async (event) => {
        const applePayMerchantData = {
          validationUrl: event.validationURL,
          frontendDomain: window.location.hostname,
          orderUuid: orderUuidRef.current,
        }
        try {
          const validateMerchantRes = await createApplePayMerchantSession({
            paymentRequestId,
            applePayMerchant: applePayMerchantData,
          })
          const { applePayMerchant } = validateMerchantRes
          const { session: resSession } = applePayMerchant
          session.completeMerchantValidation(resSession)
        } catch (err) {
          onError(err)
          onSetLoading(false)
          onDeclineOrder(orderUuidRef.current)
          try {
            session.abort()
          } catch (error) {
            // do nothing
          }
        }
      }
      session.oncancel = (event) => {
        onSetLoading(false)
        onDeclineOrder(orderUuidRef.current)
      }
      session.onpaymentauthorized = async (event) => {
        const tokenStr = btoa(JSON.stringify(event.payment.token.paymentData))

        try {
          const authorization = await createPaymentResponse({
            paymentResponse: {
              paymentRequestId,
              payloadParams: { token: tokenStr },
            },
          })

          if (authorization.status === 'success') {
            try {
              session.completePayment(ApplePaySession.STATUS_SUCCESS)
            } catch (err) {
              console.log(err)
            } finally {
              navigate(
                `/checkout/payment-processing/?uuid=${orderUuidRef.current}`,
              )
            }
          }
        } catch (err) {
          onError(err)

          try {
            session.completePayment(ApplePaySession.STATUS_FAILURE)
          } catch (error) {
            console.log(error)
          } finally {
            onSetLoading(false)
            onDeclineOrder(orderUuidRef.current)
          }
        }
      }
      session.begin()
    } catch (createSessionError) {
      onError(createSessionError)
      onSetLoading(false)
      onDeclineOrder(orderUuidRef.current)
    }
  }

  async function handleCreatePaymentRequest(order) {
    const {
      paymentProviderInternalCode,
      paymentGatewayServiceCode,
      id: paymentProviderId,
    } = selectedPaymentProvider
    const amount = _.get(order, 'priceDetails.totalPrice') || _.get(order, 'priceDetails.orderTotalPrice', '0.0')
    const paymentMethodType = `${_.toUpper(paymentGatewayServiceCode)}___${paymentProviderInternalCode}`
    orderUuidRef.current = `${order.uuid}`
    const paymentProcessingUrl = getPath(`/checkout/payment-processing/?uuid=${order.uuid}&cartId=${isolateCart.id}`)
    const redirectUrl = `${window.location.origin}${paymentProcessingUrl}`
    // prepare payment request payload data
    const paymentRequestData = {
      orderId: order.id,
      orderUuid: order.uuid,
      paymentProviderId,
      amount,
    }

    try {
      switch (paymentMethodType) {
        // pay2go
        case 'PAY2GO___pay2go':
        {
          const additionalPaymentRequestReqData = {
            redirectUrl,
          }

          const { paymentRequest } = await createPaymentRequest({
            paymentRequest: {
              ...paymentRequestData,
              ...additionalPaymentRequestReqData,
            },
          })

          store.set(tempCacheKey, order.uuid)

          submitPay2goForm({ paymentRequest })
          break
        }
        case 'NEWEBPAY___newebpay':
        case 'NEWEBPAY___newebpay_cvsb2c':
        {
          const additionalPaymentRequestReqData = {
            redirectUrl,
          }

          const { paymentRequest } = await createPaymentRequest({
            paymentRequest: {
              ...paymentRequestData,
              ...additionalPaymentRequestReqData,
            },
          })

          store.set(tempCacheKey, order.uuid)

          submitNewebpayForm({ paymentRequest })
          break
        }
        // cybersource payment
        case 'CARD_DEMO___DEMO_CARD':
        case 'CYBERSOURCE___CYBS_VM':
        case 'CYBERSOURCE___CYBS_VM_3DS':
          {
            const additionalPaymentRequestReqData = {
              mode: 'SA',
              redirectUrl,
            }

            const { paymentRequest } = await createPaymentRequest({
              paymentRequest: {
                ...paymentRequestData,
                ...additionalPaymentRequestReqData,
              },
            })

            // save previous order uuid, for decline order when click browser back
            store.set(tempCacheKey, order.uuid)

            submitCybersourceForm({ paymentRequest })
          }
          break
        case 'CYBERSOURCE_APPLEPAY___CYBS_APPLEPAY_WEB':
          {
            if (ApplePaySession) {
              const { appleMerchantId } = selectedPaymentProvider
              if (!ApplePaySession.canMakePaymentsWithActiveCard(appleMerchantId)) {
                throw t('screens.checkout.checkout.applePay.error.invalidCard')
              }
            } else {
              throw t('screens.checkout.checkout.applePay.error.invalidCard')
            }
            const additionalPaymentRequestReqData = {
              mode: 'APPLEPAY_ON_WEB',
            }

            const { paymentRequest } = await createPaymentRequest({
              paymentRequest: {
                ...paymentRequestData,
                ...additionalPaymentRequestReqData,
              },
            })

            // save previous order uuid, for decline order when click browser back
            store.set(tempCacheKey, order.uuid)

            if (paymentRequest) {
              setIsApplePayConfirmationDialogOpen(true)
              applePayPaymentRequestRef.current = paymentRequest
            }
          }
          break

        // qfpay Alipay WAP
        case 'QFPAY_OVERSEAS___ALIPAY_H5_WAP_MAINLAND_HK_ALIPAYHK':
        case 'QFPAY_OVERSEAS___ALIPAY_H5_WAP_MAINLAND_HK_ALIPAYCN':
          {
            const additionalPaymentRequestReqData = {
              h5_complete_url: redirectUrl,
            }

            const { paymentRequest } = await createPaymentRequest({
              paymentRequest: {
                ...paymentRequestData,
                ...additionalPaymentRequestReqData,
              },
            })

            // save previous order uuid, for decline order when click browser back
            store.set(tempCacheKey, order.uuid)

            const payUrl = _.get(paymentRequest, 'paymentData.payUrl')
            if (_.isEmpty(payUrl)) {
              throw new Error('redirect url not exist')
            }

            // redirect to Alipay WAP
            window.location.href = payUrl
          }
          break

        // qfpay WeChat H5 Web
        case 'QFPAY_OVERSEAS___WECHAT_H5_WEB_MAINLAND_HK':
          {
            // Get public ip
            // eslint-disable-next-line camelcase
            const { data: h5_spbill_create_ip } = await axios.get('https://api.ipify.org')
            const additionalPaymentRequestReqData = {
              h5_complete_url: redirectUrl,
              h5_wap_url: window.location.origin,
              h5_wap_name: getSystemSetting('frontend.site_name'),
              h5_spbill_create_ip,
            }
            const { paymentRequest } = await createPaymentRequest({
              paymentRequest: {
                ...paymentRequestData,
                ...additionalPaymentRequestReqData,
              },
            })

            // save previous order uuid, for decline order when click browser back
            store.set(tempCacheKey, order.uuid)

            const payUrl = _.get(paymentRequest, 'paymentData.payUrl')
            if (_.isEmpty(payUrl)) {
              throw new Error('redirect url not exist')
            }

            // redirect to WeChatPay
            window.location.href = payUrl
          }
          break

        // qfpay WeChat H5
        case 'QFPAY_OVERSEAS___WECHAT_H5_MAINLAND_HK':
          if (_.isEmpty(openid) || !_.isEmpty(store.get('openid'))) {
            store.remove('openid')
            // save payment method in local storage
            store.set('checkoutPaymentMethod', paymentProviderInternalCode)
            // redirect url after oauth
            const path = getPath(`/checkout/?id=${isolateCart.id}&orderUuid=${order.uuid}`)
            const oAuthRedirectURL = `${window.location.origin}${path}`
            const { qfpayOverseasOauths } = await fetchQfpayOverseaOauthUrl({
              orderId: order.id,
              paymentProviderId,
              oAuthRedirectURL,
            })
            const { oauthUrl } = qfpayOverseasOauths

            // redirect to oauth url
            window.location.replace(oauthUrl)
          } else {
            // save open id in local storage for compare, because openid cannot be reused
            store.set('openid', openid)

            // if openid is exist, mean already proceed oauth and able to create payment request
            // and do payment in WeChat
            const additionalPaymentRequestReqData = {
              openid,
              h5_complete_url: redirectUrl,
            }
            const { paymentRequest } = await createPaymentRequest({
              paymentRequest: {
                ...paymentRequestData,
                ...additionalPaymentRequestReqData,
              },
            })
            const payUrl = _.get(paymentRequest, 'paymentData.payUrl')

            if (_.isEmpty(payUrl)) {
              throw new Error('redirect url not exist')
            }

            // redirect to WeChatPay
            window.location.replace(payUrl)
            setTimeout(() => {
              navigate(
                `/checkout/payment-processing/?uuid=${order.uuid}`,
              )
            }, 10000)
          }
          break

        case 'QFPAY_OVERSEAS___QR_PAY_WECHAT_MAINLAND_HK':
        case 'QFPAY_OVERSEAS___QR_PAY_FPS':
        case 'QFPAY_OVERSEAS___QR_PAY_UNIONPAY_QUICKPASS':
        {
          const additionalPaymentRequestReqData = {
          }
          const { paymentRequest } = await createPaymentRequest({
            paymentRequest: {
              ...paymentRequestData,
              ...additionalPaymentRequestReqData,
            },
          })
          const { paymentData = {} } = paymentRequest
          const { qrcode, qrcodeContent } = paymentData
          if (qrcodeContent || qrcode) {
            const paymentTypeMapping = {
              QFPAY_OVERSEAS___QR_PAY_WECHAT_MAINLAND_HK: 'wechat',
              QFPAY_OVERSEAS___QR_PAY_FPS: 'fps',
              QFPAY_OVERSEAS___QR_PAY_UNIONPAY_QUICKPASS: 'unionpay',
            }
            const paymentType = _.get(paymentTypeMapping, paymentMethodType, '')
            onPaymentQrCodeModalOpen({ paymentType, qrCode: qrcodeContent || qrcode })
          } else {
            throw new Error('qrcode does not exist')
          }
          break
        }

        case 'COD___cod':
        case 'CUSTOMER_MANUAL___BANK_TRANSFER':
          {
            const { paymentRequest } = await createPaymentRequest({
              paymentRequest: {
                ...paymentRequestData,
              },
            })
            if (paymentRequest) {
              navigate(
                `/checkout/payment-processing/?uuid=${order.uuid}`,
              )
            }
          }
          break
        default:
          break
      }
    } catch (error) {
      throw error
    }
  }

  async function handleConfirmPlaceOrder() {
    let currentOrder = null
    try {
      onSetLoading(true)
      currentOrder = await onConfirmPlaceOrder()
      if (!isOrderRequirePayment(currentOrder)) {
        await onCheckoutCompleted(currentOrder)
        return
      }
      await handleCreatePaymentRequest(currentOrder)
    } catch (error) {
      onError(error)
      // decline order if order is already created
      if (!_.isEmpty(currentOrder)) {
        await onDeclineOrder(currentOrder.uuid)
      }
      onSetLoading(false)
    }
  }

  function isOrderRequirePayment(order) {
    const remainingAmount = flow(
      get('remainingAmount'),
      toNumber,
    )(order)
    return remainingAmount > 0
  }

  useEffect(() => {
    if (isApplePayConfirmationDialogOpen) {
      onSetLoading(false)
    }
  }, [isApplePayConfirmationDialogOpen])

  function handleApplePayDialogRequestClose() {
    setIsApplePayConfirmationDialogOpen(false)
    onDeclineOrder(orderUuidRef.current)
  }

  function handleConfirmApplePayClick() {
    applePayBeginPayment({ paymentRequest: applePayPaymentRequestRef.current })
    setIsApplePayConfirmationDialogOpen(false)
  }

  function onPaymentQrCodeModalOpen({ paymentType, qrCode }) {
    setPaymentQrCodeModalParams({
      paymentType,
      qrCode,
      orderUuid: orderUuidRef.current,
    })
    setPaymentQrCodeModalOpen(true)
    onSetLoading(false)
  }

  async function handlePaymentQrCodeModalClose() {
    setPaymentQrCodeModalOpen(false)
    onDeclineOrder(orderUuidRef.current)
  }

  async function handlePaymentQrCodeModalSuccess() {
    navigate(
      `/checkout/payment-processing/?uuid=${orderUuidRef.current}`,
    )
  }

  async function handlePaymentQrCodeModalError(error) {
    setPaymentQrCodeModalOpen(false)
    onError(error)
    onDeclineOrder(orderUuidRef.current)
  }

  const viewProps = {
    active,
    checkoutDisabled,
    isolateCart,
    isApplePayConfirmationDialogOpen,
    paymentQrCodeModalOpen,
    paymentQrCodeModalParams,
    onApplePayDialogRequestClose: handleApplePayDialogRequestClose,
    onConfirmApplePayClick: handleConfirmApplePayClick,
    onConfirmPlaceOrder: handleConfirmPlaceOrder,
    onPaymentQrCodeModalClose: handlePaymentQrCodeModalClose,
    onPaymentQrCodeModalSuccess: handlePaymentQrCodeModalSuccess,
    onPaymentQrCodeModalError: handlePaymentQrCodeModalError,
  }

  return (
    <ConfirmationView {...viewProps} />
  )
}

export default ConfirmationController
