import _ from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './collection-style'
import Title from '../title/title-view'
import Info from '../info/info-view'
import getStoreInfoFromDescription from '../../../../helpers/get-store-info-from-description'
import {
  Button,
  Dropdown,
} from '../../../../ui'
import {
  customStylesOnlyText,
} from '../../../../ui/dropdown/custom-styles'

const CollectionView = ({
  active,
  availablePickupTimeSlot,
  collectionTime,
  confirmed,
  fnbEnabled,
  onChangeStore,
  onCollectionTimeChange,
  onConfirm,
  selectedPickupTime,
  store,
}) => {
  // Handle Address from Description
  const { address = {}, description = '' } = store
  const {
    formattedAddressLines = [],
    ...otherAddressProps
  } = address
  const descriptionHtml = (description || '').trim()
  const descriptionAddressLines = _.map(getStoreInfoFromDescription('address', descriptionHtml), 'content')
  const addressLines = _.isEmpty(descriptionAddressLines)
    ? formattedAddressLines : descriptionAddressLines
  const infoAddress = {
    formattedAddressLines: addressLines,
    ...otherAddressProps,
  }

  const { t } = useTranslation()
  const isCollectionTimeReady = !_.isEmpty(collectionTime)
  const {
    collectionStyle,
    btnContinueStyle,
    selectedDateStyle,
    titleTimeStyle,
  } = useStyles({ confirmed })

  const timeslotOptions = useMemo(() => (
    _.map(availablePickupTimeSlot, (timeslot) => ({
      value: timeslot,
      label: moment(`${moment().format('YYYY-MM-DD')} ${timeslot}`).format('LT'),
    }))
  ), [availablePickupTimeSlot])

  if (!active) return null

  return (
    <div className={collectionStyle}>
      <Title
        isActive
        t={t}
        text={t('screens.checkout.checkout.collection.title')}
        buttonText={t('screens.checkout.checkout.buttons.edit')}
      />
      <Info
        t={t}
        isAddress
        isHtmlAddress
        address={infoAddress}
        title={t('screens.checkout.checkout.collection.subtitle')}
        onOpenAddressBook={onChangeStore}
      />
      {
        fnbEnabled && (
          <>
            <h4>{t('screens.checkout.checkout.collection.date')}</h4>
            {
              confirmed ? (
                <p className={selectedDateStyle}>{moment(collectionTime).format('YYYY-MM-DD, LT')}</p>
              ) : (
                <>
                  {/* <p>{t('screens.checkout.checkout.collection.available')}</p> */}
                  {/* <DatePicker t={t} /> */}
                  <h5 className={titleTimeStyle}>{t('screens.checkout.checkout.collection.time')}</h5>
                  <Dropdown
                    widthDropdown="370px"
                    placeholder={t('screens.checkout.checkout.collection.dropdownPlaceholder')}
                    customStyles={customStylesOnlyText}
                    options={timeslotOptions}
                    onChange={onCollectionTimeChange}
                    values={_.find(timeslotOptions, { value: selectedPickupTime })}
                  />
                  <Button
                    width="370px"
                    className={btnContinueStyle}
                    uppercase
                    dark
                    disabled={!isCollectionTimeReady}
                    text={t('screens.checkout.checkout.buttons.continue')}
                    onClick={onConfirm}
                  />
                </>
              )
            }
          </>
        )
      }
    </div>
  )
}

export default CollectionView
