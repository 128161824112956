import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  qrCodeModal: {
    bottom: 0,
    borderTop: '6px solid #000',
    position: 'fixed',
    top: 50,
    width: '100vw',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingTop: 11,
    padding: [11, 14, 0, 0],
    cursor: 'pointer',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    justifyContent: 'start',
  },
  messageText: {
    color: '#818181',
    fontFamily: 'Helvetica',
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 0.07,
    lineHeight: '24px',
    marginBottom: 30,
    textAlign: 'center',
    display: 'block',
  },
  qrcodeContainer: {
    marginBottom: '3rem',
    position: 'relative',
  },
  qrcodeImage: {
    imageRendering: 'pixelated',
    fallbacks: {
      imageRendering: 'crisp-edges',
    },
    minWidth: '17rem',
  },
  title: {
    color: '#232323',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.44,
    marginBottom: '4.8rem',
    paddingTop: 27,
    textAlign: 'center',
  },
  '@media only screen and (min-width: 768px)': {
    qrCodeModal: {
      minWidth: 500,
      width: 500,
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      padding: 0,
    },
    closeButton: {
      paddingTop: 16,
    },
    content: {
      height: 440,
    },
    messageText: {
      display: 'block',
    },
    qrcodeContainer: {
    },
    title: {
      paddingTop: '4.7rem',
    },
  },
})

export default useStyles
