/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import useStyles from './payment-style'
import Title from '../title/title-view'
import Info from '../info/info-view'
import { AddressManager, InputRadio, Checkbox } from '../../../../ui'
import combineClassNames from '../../../../helpers/combineClassNames'

const PaymentView = ({
  active,
  availablePaymentProviders,
  billingAddress,
  billingSameAsShipping,
  defaultBillingAddress,
  deliveryAddresses,
  deliveryType,
  forceAddAddress,
  isAllowedBillingAddress,
  isGuest,
  selectedPaymentProvider,
  showAddressBook,
  onCloseAddressBook,
  onDeleteAddress,
  onFetchDeliveryAddresses,
  onOpenAddressBook,
  onSelectAddress,
  onSelectPaymentMethod,
  onUpdateBillingSameAsShipping,
  onUpdateDeliveryAddress,
}) => {
  const { t } = useTranslation()
  const {
    paymentStyle,
    boxPayments,
    contentPayment,
    optionsStyle,
    contentOptions,
    textOptions,
    boxInput,
    textCheckbox,
    onSelect,
    isCloseStyle,
    titlePaymentStyle,
    itemOptionsStyle,
    paymentDescription,
  } = useStyles({ active })
  const selectedPaymentProviderId = _.get(selectedPaymentProvider, 'id')
  const [showAddressBookPayment, setShowAddressBookPayment] = useState(false)

  const handleCloseAddressBook = () => {
    setShowAddressBookPayment(false)
  }

  const handleSelectPaymentMethod = (e) => {
    const { value } = e.target
    onSelectPaymentMethod(_.toInteger(value))
  }

  const handleOpenAddressBook = () => {
    setShowAddressBookPayment(!showAddressBookPayment)
  }

  const handleUpdateBillingSameAsShipping = () => {
    onUpdateBillingSameAsShipping()
  }

  const ImagePayment = ({ children, ...props }) => {
    const classes = useStyles(props)
    return (
      <div className={classes.contentOptions} />
    )
  }

  const handleUpdateDeliveryAddress = (address) => {
    onUpdateDeliveryAddress(address)
    setShowAddressBookPayment(false)
  }

  return (
    <div className={combineClassNames([paymentStyle, !active && isCloseStyle])}>
      <Title
        isActive={active}
        text={t('screens.checkout.checkout.payment.title')}
        isEditable={!billingSameAsShipping}
        buttonText={t('screens.checkout.checkout.buttons.edit')}
        onEdit={handleOpenAddressBook}
      />
      {
        active
          ? (
            <form className={contentPayment}>
              <div className={boxPayments}>
                {
                  availablePaymentProviders.map((paymentProvider) => (
                    <div key={`payment-method-${paymentProvider.id}`} className={itemOptionsStyle}>
                      <div className={optionsStyle}>
                        <div className={boxInput}>
                          <InputRadio
                            checked={paymentProvider.id === selectedPaymentProviderId}
                            id={paymentProvider.id}
                            name="paymentMethod"
                            value={paymentProvider.id}
                            onChange={handleSelectPaymentMethod}
                          />
                        </div>
                        <ImagePayment iconDataUri={paymentProvider.iconDataUri} />

                        <span className={textOptions}>{paymentProvider.name}</span>
                      </div>
                      {
                        !_.isEmpty(paymentProvider.description)
                          && paymentProvider.id === selectedPaymentProviderId
                          && (
                          <div
                            className={paymentDescription}
                            dangerouslySetInnerHTML={
                              { __html: paymentProvider.description }
                            }
                          />
                          )
                      }
                    </div>
                  ))
                }
              </div>
              {

                isAllowedBillingAddress && (
                  <>
                    {
                      !forceAddAddress && (
                        <div>
                          <Checkbox
                            checked={billingSameAsShipping}
                            onChange={handleUpdateBillingSameAsShipping}
                          >
                            <p className={textCheckbox}>{t('screens.checkout.checkout.payment.billing', { context: _.camelCase(deliveryType) })}</p>
                          </Checkbox>
                        </div>
                      )
                    }
                    {
                      (!billingSameAsShipping && showAddressBookPayment) && (
                        <AddressManager
                          addresses={deliveryAddresses}
                          addressSelectable
                          addressType="billing"
                          autoSelectPrimaryAddress
                          closeAfterSelectAddress
                          defaultAddress={defaultBillingAddress}
                          disableAddressBook={isGuest}
                          selectedAddress={billingAddress}
                          onAddress
                          onClose={handleCloseAddressBook}
                          onDeleteAddress={onDeleteAddress}
                          onFetchDeliveryAddresses={onFetchDeliveryAddresses}
                          onSelectAddress={onSelectAddress}
                          onUpdateDeliveryAddress={handleUpdateDeliveryAddress}
                        />
                      )
                    }
                    {
                      (!billingSameAsShipping && !showAddressBookPayment) && (
                        <Info
                          t={t}
                          isAddress
                          address={billingAddress}
                          title={t('screens.checkout.checkout.payment.subtitle')}
                          onOpenAddressBook={onOpenAddressBook}
                        />
                      )
                    }
                  </>
                )
              }
            </form>
          )
          : ''
        }
    </div>
  )
}

export default PaymentView
