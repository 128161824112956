/* eslint-disable */

import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import Title from '../title/title-view'
import { combineClassNames } from '../../../../helpers'
import { AddressManager, Dropdown, Input, InputRadio } from '../../../../ui'
import {
  customStylesOnlyText,
} from '../../../../ui/dropdown/custom-styles'
import useStyles from './meta-tw-invoice-style'
import Info from "../info/info-view";

export default function MetaTwInvoiceView(props) {
  const {
    active,
    additionalFields,
    defaultAddress,
    deliveryAddresses,
    errors,
    isGuest,
    metaDefinitions,
    showAddressBook,
    twInvoiceDeliveryAddress,
    taiwanInvoiceMeta,
    taiwanInvoiceType,
    onBlur,
    onChange,
    onCloseAddressBook,
    onDeleteAddress,
    onFetchDeliveryAddresses,
    onOpenAddressBook,
    onSelect,
    onSelectAddress,
    onSelectRadio,
    onUpdateDeliveryAddress,
  } = props
  const { t } = useTranslation()
  const styles = useStyles()

  function renderAdditionalFields(field) {
    switch(field.type) {
      case 'select':
        const selectedItem = _.find(field.options, ['value', taiwanInvoiceMeta[field.key]])
        return (
          <div>
            <Dropdown
              className={styles.dropdown}
              disabled={_.isEmpty(field.options)}
              label={field.label}
              isSearchable={false}
              customStyles={customStylesOnlyText}
              options={field.options}
              values={selectedItem}
              onChange={onSelect}
            />
            { errors[field.key] && <p className={styles.error}>{errors[field.key]}</p> }
          </div>
        )
      case 'text':
        return (
          <Input
            errorMessage={errors[field.key]}
            label={field.label}
            name={field.key}
            required={true}
            value={taiwanInvoiceMeta[field.key]}
            onBlur={onBlur}
            onChange={onChange}
          />
        )
      default:
        return null
    }
  }

  function renderRadioOptions(fieldKey) {
    const metaObject = _.find(metaDefinitions, ['fieldKey', fieldKey])
    const {
      fieldName,
      editable,
      metaDefinitionValueListItems = []
    } = metaObject || {}
    const enabledItems = metaDefinitionValueListItems.filter((listItem) => listItem.enabled)
    if (!editable) return null
    return (
      <React.Fragment>
        <h4>{fieldName}</h4>
        {
          enabledItems.map((item) => (
            <div key={`invoice-meta-${fieldKey}-${item.value}`} className={styles.options}>
              <div className={styles.boxInput}>
                <InputRadio
                  id={`${fieldKey}-${item.value}`}
                  checked={taiwanInvoiceType === item.value}
                  onChange={onSelectRadio.bind(null, fieldKey, item.value)}
                />
              </div>
              <div className={styles.contentOptions}>
                <h6>{item.name}</h6>
              </div>
            </div>
          ))
        }
      </React.Fragment>
    )
  }

  return (
    <div className={combineClassNames([styles.invoiceRoot, !active && styles.isClose])}>
      <Title isActive={active} text={t('screens.checkout.checkout.metaInvoice.title')} />
      {
        active
          ? (
            <>
              {
                (showAddressBook || _.isEmpty(twInvoiceDeliveryAddress)) && (
                  <AddressManager
                    addresses={deliveryAddresses}
                    addressSelectable
                    addressType="billing"
                    autoSelectPrimaryAddress
                    closeAfterSelectAddress
                    defaultAddress={defaultAddress}
                    disableAddressBook={isGuest}
                    selectedAddress={twInvoiceDeliveryAddress}
                    onAddress
                    onClose={onCloseAddressBook}
                    onDeleteAddress={onDeleteAddress}
                    onFetchDeliveryAddresses={onFetchDeliveryAddresses}
                    onSelectAddress={onSelectAddress}
                    onUpdateDeliveryAddress={onUpdateDeliveryAddress}
                  />
                )
              }
              {
                (!showAddressBook) && (
                  <Info
                    t={t}
                    isAddress
                    address={twInvoiceDeliveryAddress}
                    title={t('screens.checkout.checkout.metaInvoice.address')}
                    canOpenAddressBook={true}
                    onOpenAddressBook={onOpenAddressBook}
                  />
                )
              }
              {/* show invoice type */}
              <div>
                { !_.isEmpty(metaDefinitions) && renderRadioOptions('tw_invoice_type') }
              </div>
              {/* loop additional field base on invoice type */}
              <div>
                { additionalFields.map(renderAdditionalFields) }
              </div>
            </>
          )
          : null
      }
    </div>
  )
}
