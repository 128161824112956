/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Dropdown,
} from '../../../../../../ui'
import useStyles from './select-time-delivery-style'
import {
  customStylesOnlyText,
} from '../../../../../../ui/dropdown/custom-styles'

const SelectTimeDeliveryView = ({
  availableDeliveryDate,
  availableDeliveryTimeSlots,
  deliveryDateUsable,
  deliveryTimeSlotUsable,
  shipment,
  t,
  onSelectDeliveryDate,
  onSelectDeliveryTimeSlot,
  requireDeliveryAddress,
  requireCourierService,
}) => {
  const [isClearable, setIsClearable] = useState(false)
  const availableDeliveryDateOptions = useMemo(() => (
    availableDeliveryDate.map((date) => (
      {
        label: date,
        value: date,
      }
    ))
  ), [availableDeliveryDate])

  const availableDeliveryTimeSlotOptions = useMemo(() => {
    if (_.isEmpty(availableDeliveryTimeSlots)) return []

    return availableDeliveryTimeSlots.map((slot) => (
      {
        label: slot.label,
        value: slot.id,
      }
    ))
  }, [availableDeliveryTimeSlots])
  const selectedDeliveryDate = useMemo(() => {
    if (_.isEmpty(shipment) || _.isEmpty(shipment.deliveryDate)) return

    return _.get(shipment, 'deliveryDate')
  }, [shipment])
  const selectedDeliveryTimeSlot = useMemo(() => {
    if (_.isEmpty(shipment)) return

    return _.get(shipment, 'deliveryTimeSlot.label')
  }, [shipment])

  const selectedDeliveryTimeSlotValue = useMemo(() => {
    if (_.isEmpty(shipment)) return

    return _.get(shipment, 'deliveryTimeSlot.id')
  }, [shipment])

  const handleSelectDeliveryDate = (selected) => {
    onSelectDeliveryDate(selected.value)
  }

  const handleSelectDeliveryTimeSlots = (selected) => {
    if (!_.isNull(selected)) {
      onSelectDeliveryTimeSlot(selected.value)
    } else {
      onSelectDeliveryTimeSlot(null)
    }
  }

  useEffect(() => {
    const clearInputTimeSlot = _.get(shipment, 'requiredFields')
    setIsClearable(!_.includes(clearInputTimeSlot, 'delivery_time_slot_id'))
  }, [shipment])

  const { dropdownStyle, selectTimeStyle } = useStyles()
  return (
    <div className={selectTimeStyle}>
      {
        deliveryDateUsable && (
          <Dropdown
            className={dropdownStyle}
            disabled={_.isEmpty(availableDeliveryDateOptions)}
            widthDropdown="calc(50% - 1rem)"
            placeholder={selectedDeliveryDate}
            isSearchable={false}
            customStyles={customStylesOnlyText}
            options={availableDeliveryDateOptions}
            label={!requireDeliveryAddress && requireCourierService ? t('screens.checkout.checkout.catering.date') : t('screens.checkout.checkout.shipping.date')}
            onChange={handleSelectDeliveryDate}
          />
        )
      }
      {
        deliveryTimeSlotUsable && (
          <Dropdown
            isClearable={isClearable}
            className={dropdownStyle}
            disabled={_.isEmpty(availableDeliveryTimeSlotOptions)}
            widthDropdown="calc(50% - 1rem)"
            placeholder={selectedDeliveryTimeSlot}
            valueSelect={selectedDeliveryTimeSlotValue}
            isSearchable={false}
            customStyles={customStylesOnlyText}
            options={availableDeliveryTimeSlotOptions}
            label={!requireDeliveryAddress && requireCourierService ? t('screens.checkout.checkout.catering.time') : t('screens.checkout.checkout.shipping.time')}
            onChange={handleSelectDeliveryTimeSlots}
          />
        )
      }
    </div>
  )
}

export default SelectTimeDeliveryView
