/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, {
  forwardRef, useImperativeHandle, useEffect, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  useMetaDefinitions, useUser,
} from 'react-omnitech-api'
import MetaTwInvoiceView from './meta-tw-invoice-view'

const managedMetaKeys = [
  'tw_invoice_donate',
  'tw_invoice_electronic_mobile_barcode',
  'tw_invoice_title',
  'tw_invoice_type',
  'tw_invoice_uniform_sn',
]

function MetaTwInvoiceController(props, ref) {
  const {
    deliveryAddresses,
    isolateCart,
    progress,
    onAddCartMeta,
    onDeleteAddress,
    onFetchDeliveryAddresses,
    onUpdateDeliveryAddress,
  } = props

  // Only support a single shipment for now
  const shipment = _.get(isolateCart, 'cartShipments.0')
  const defaultAddress = _.get(shipment, 'deliveryAddress')
    || _.find(deliveryAddresses, { isPrimary: true }) || {}

  // prepare hook
  const { t } = useTranslation()
  const { metaDefinitions } = useMetaDefinitions()
  const { user } = useUser()

  // internal state
  const [ready, setReady] = useState(false)
  const [showAddressBook, setShowAddressBook] = useState(false)
  const [taiwanInvoiceMeta, setTaiwanInvoiceMeta] = useState({})
  const [twInvoiceDeliveryAddress, setTwInvoiceDeliveryAddress] = useState(defaultAddress)
  const [errors, setErrors] = useState({})

  const isGuest = _.isEmpty(user)

  // useMemo
  const active = useMemo(() => (
    progress.indexOf('payment_method') !== -1
  ), [progress])

  const managedMetas = useMemo(() => (
    metaDefinitions.filter(({ fieldKey, metaModelName }) => (
      metaModelName === 'cart' && _.includes(managedMetaKeys, fieldKey)
    )) || []
  ), [managedMetaKeys, metaDefinitions])

  const mergedTaiwanInvoiceMeta = useMemo(() => {
    const snakeCaseMeta = {}
    _.each(
      isolateCart.meta,
      (value, key) => {
        snakeCaseMeta[_.snakeCase(key)] = value
      },
    )
    const oldMeta = _.pick(snakeCaseMeta, managedMetaKeys)
    return {
      ..._.omit(oldMeta, _.keys(taiwanInvoiceMeta)),
      ...taiwanInvoiceMeta,
    }
  }, [isolateCart.meta, taiwanInvoiceMeta])

  const taiwanInvoiceType = useMemo(() => (
    _.get(mergedTaiwanInvoiceMeta, 'tw_invoice_type')
  ), [mergedTaiwanInvoiceMeta])

  // prepare additional fields base on taiwan invoice type
  const additionalFields = useMemo(() => {
    switch (taiwanInvoiceType) {
      case 'duplicate':
        return []
      case 'triplicate': {
        const twInvoiceUniformSnMeta = _.find(managedMetas, ['fieldKey', 'tw_invoice_uniform_sn'])
        const twInvoiceTitleMeta = _.find(managedMetas, ['fieldKey', 'tw_invoice_title'])
        return [
          {
            key: 'tw_invoice_uniform_sn',
            label: _.get(twInvoiceUniformSnMeta, 'fieldName') || 'tw_invoice_uniform_sn',
            type: 'text',
          },
          {
            key: 'tw_invoice_title',
            label: _.get(twInvoiceTitleMeta, 'fieldName') || 'tw_invoice_title',
            type: 'text',
          },
        ]
      }
      case 'electronic_mobile_barcode': {
        const twInvoiceElectronicMobileBarcodeMeta = _.find(managedMetas, ['fieldKey', 'tw_invoice_electronic_mobile_barcode']) || {}
        return [
          {
            key: 'tw_invoice_electronic_mobile_barcode',
            label: _.get(twInvoiceElectronicMobileBarcodeMeta, 'fieldName') || 'tw_invoice_electronic_mobile_barcode',
            type: 'text',
          },
        ]
      }
      case 'donate': {
        const twInvoiceDonateMeta = _.find(managedMetas, ['fieldKey', 'tw_invoice_donate']) || {}
        const { metaDefinitionValueListItems = [] } = twInvoiceDonateMeta
        return [
          {
            key: 'tw_invoice_donate',
            label: _.get(twInvoiceDonateMeta, 'fieldName') || 'tw_invoice_donate',
            type: 'select',
            options: metaDefinitionValueListItems
              .filter((item) => item.enabled)
              .map((item) => ({
                key: 'tw_invoice_donate',
                label: item.name,
                value: item.value,
              })),
          },
        ]
      }
      default:
        return []
    }
  }, [managedMetas, taiwanInvoiceType])

  function getDefaultStateByType(type) {
    switch (type) {
      case 'duplicate':
        return {}
      case 'triplicate':
        return {
          tw_invoice_uniform_sn: '',
          tw_invoice_title: '',
        }
      case 'electronic_mobile_barcode':
        return {
          tw_invoice_electronic_mobile_barcode: '',
        }
      case 'donate':
        return {
          tw_invoice_donate: '',
        }
      default:
        return {}
    }
  }

  function handleBlur() {
    handleSubmit()
  }

  function handleChange(e) {
    const { name, value } = e.target
    setTaiwanInvoiceMeta({
      ...taiwanInvoiceMeta,
      [name]: value,
    })
    setErrors(_.omit(errors, name))
  }

  function handleCloseAddressBook() {
    setShowAddressBook(false)
  }

  function handleError(key, errorMsg) {
    setErrors({
      ...errors,
      [key]: errorMsg,
    })
  }

  function handleOpenAddressBook() {
    setShowAddressBook(true)
  }

  /**
   * Text input enum selector on focus event
   */
  function handleSelect(selectedItem) {
    const { key, value } = selectedItem
    setErrors({})
    // setTaiwanInvoiceType(value)
    setTaiwanInvoiceMeta({
      ...taiwanInvoiceMeta,
      [key]: value,
    })
  }

  function handleSelectAddress(address) {
    setTwInvoiceDeliveryAddress(address)
    setShowAddressBook(false)
  }

  function handleSelectRadio(fieldKey, value) {
    setErrors({})
    // setTaiwanInvoiceType(value)
    setTaiwanInvoiceMeta({
      [fieldKey]: value,
      ...getDefaultStateByType(value),
    })
  }

  async function handleSubmit() {
    let metaPayload = {}

    if (_.isEmpty(taiwanInvoiceType)) {
      const metaObject = _.find(mergedTaiwanInvoiceMeta, { fieldKey: 'tw_invoice_type' })
      handleError(
        'tw_invoice_type',
        t('screens.checkout.metaInvoice.isRequired', { field: metaObject.fieldName }),
      )
    }

    switch (taiwanInvoiceType) {
      case 'duplicate':
        // Submitting type 二聯式發票
        metaPayload = { tw_invoice_type: taiwanInvoiceType }
        break

      case 'electronic_mobile_barcode':
        // Submitting type 手機條碼載具
        if (performRegexChecks(['tw_invoice_electronic_mobile_barcode'])) {
          metaPayload = _.pick(taiwanInvoiceMeta, ['tw_invoice_type', 'tw_invoice_electronic_mobile_barcode'])
        }
        break

      case 'donate':
        // Submitting type 愛心碼
        if (performRegexChecks(['tw_invoice_donate'])) {
          metaPayload = _.pick(taiwanInvoiceMeta, ['tw_invoice_type', 'tw_invoice_donate'])
        }
        break

      // Submitting type 三聯式發票
      case 'triplicate':
        if (performRegexChecks(['tw_invoice_uniform_sn', 'tw_invoice_title'])) {
          metaPayload = _.pick(taiwanInvoiceMeta, ['tw_invoice_type', 'tw_invoice_uniform_sn', 'tw_invoice_title'])
        }
        break

      default:
        // Something went wrong selected invoiceType case not yet implemented
        console.warn(`Submission failed, invoiceType ${taiwanInvoiceType} case not yet implemented.`)
        break
    }

    if (!_.isEmpty(metaPayload)) {
      const deliveryAddressId = _.get(shipment, 'deliveryAddressId') || _.get(twInvoiceDeliveryAddress, 'id')
      const deliveryAddress = _.get(shipment, 'deliveryAddress') || twInvoiceDeliveryAddress
      await onAddCartMeta({
        newMeta: {
          ...metaPayload,
          twInvoiceDeliveryAddressId: deliveryAddressId,
          twInvoiceDeliveryAddressStr: deliveryAddress.formattedAddressLines.join(','),
        },
        removeKeys: _.filter(managedMetaKeys, (key) => !_.includes(Object.keys(metaPayload), key)),
      })
    }
  }

  /**
   * Performs regex check on all input with key names as defined in invoiceInputKeys[]
   * @param invoiceInputKeys: array containing all text input key names that require regex check.
   * @Return
   *  true:   if regex check for ALL text input defined in invoiceInputKeys[] passed.
   *  false:  if regex check fails for ONE of text inputs defined in invoiceInputKeys[] fails.
   *
   */
  function performRegexChecks(invoiceInputKeys = []) {
    for (let i = 0; i < invoiceInputKeys.length; i++) {
      const key = invoiceInputKeys[i]
      const metaObject = _.find(metaDefinitions, { fieldKey: key })
      const { validateFormat = '', validateFormatErrorMessage = '', editable = false } = metaObject

      if (editable) {
        const inputValue = taiwanInvoiceMeta[key]
        if (_.isEmpty(inputValue)) {
          handleError(key, t('screens.checkout.metaInvoice.isRequired', { field: metaObject.fieldName }))
          return false
        }

        // Only perform pattern.test on editable input fields.
        const pattern = new RegExp(validateFormat);
        if (!pattern.test(inputValue)) {
          handleError(key, validateFormatErrorMessage)
          return false
        }
      }
    }

    return true
  }

  /**
   * validateTaiwanInvoiceMeta
   * exposed to parent for validate taiwan invoice meta to disable checkout
   */
  function validate() {
    // - check invoice type
    if (_.isEmpty(taiwanInvoiceType)) return false

    // - do performRegexChecks
    switch (taiwanInvoiceType) {
      // type 二聯式發票
      case 'duplicate':
        return true
      // type 手機條碼載具
      case 'electronic_mobile_barcode':
        return performRegexChecks(['tw_invoice_electronic_mobile_barcode'])
      // type 愛心碼
      case 'donate':
        return performRegexChecks(['tw_invoice_donate'])
      // type 三聯式發票
      case 'triplicate':
        return performRegexChecks(['tw_invoice_uniform_sn', 'tw_invoice_title'])
      // Something went wrong selected invoiceType case not yet implemented
      default:
        console.warn(`Submission failed, invoiceType ${taiwanInvoiceType} case not yet implemented.`)
        return false
    }
  }

  useEffect(() => {
    if (_.isEmpty(isolateCart.meta) || ready) return

    const newMeta = {}
    _.each(
      isolateCart.meta,
      (value, key) => {
        newMeta[_.snakeCase(key)] = value
      },
    )

    setTaiwanInvoiceMeta(_.pick(newMeta, managedMetaKeys))
    setReady(true)
  }, [isolateCart.meta, ready])

  useEffect(() => {
    if (
      _.isEmpty(taiwanInvoiceMeta)
      || _.includes(['triplicate', 'electronic_mobile_barcode'], taiwanInvoiceType)
    ) return

    handleSubmit()
  }, [taiwanInvoiceType, taiwanInvoiceMeta])

  useImperativeHandle(ref, () => ({
    validate,
  }));

  const viewProps = {
    active,
    additionalFields,
    defaultAddress,
    deliveryAddresses,
    errors,
    isGuest,
    metaDefinitions: managedMetas,
    progress,
    showAddressBook,
    twInvoiceDeliveryAddress,
    taiwanInvoiceType,
    taiwanInvoiceMeta: mergedTaiwanInvoiceMeta,
    onBlur: handleBlur,
    onChange: handleChange,
    onCloseAddressBook: handleCloseAddressBook,
    onDeleteAddress,
    onFetchDeliveryAddresses,
    onOpenAddressBook: handleOpenAddressBook,
    onSelect: handleSelect,
    onSelectAddress: handleSelectAddress,
    onSelectRadio: handleSelectRadio,
    onUpdateDeliveryAddress,
  }

  return (
    <MetaTwInvoiceView {...viewProps} />
  )
}

export default forwardRef(MetaTwInvoiceController)
